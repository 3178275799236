var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Team-pane"},[_c('div',{staticClass:"pane-header"},[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push('/combination/edit')}}},[_vm._v("新增拼团活动")])],1),_c('div',{staticClass:"pane-table m-t-16"},[_c('el-table',{ref:"paneTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.value,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"name","label":"活动名称","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"activity_time","label":"活动时间","min-width":"250"}}),_c('el-table-column',{attrs:{"prop":"closing_order","label":"拼团订单","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"sales_amount","label":"拼团销售额","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"sales_volume","label":"拼团销售量","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"活动状态","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 1)?_c('el-tag',{attrs:{"size":"medium","type":"danger"}},[_vm._v("未开始")]):(scope.row.status == 2)?_c('el-tag',{attrs:{"size":"medium","type":"success"}},[_vm._v("进行中")]):_c('el-tag',{attrs:{"size":"medium","type":"info"}},[_vm._v("已结束")])]}}])}),_c('el-table-column',{attrs:{"prop":"create_time","label":"创建时间","min-width":"180"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","min-width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                                path: '/combination/edit',
                                query: {
                                    id: scope.row.id,
                                    disabled: true,
                                    status: 0
                                }
                            })}}},[_vm._v("详情")]),(scope.row.status !== 3)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                                path: '/combination/edit',
                                query: {
                                    id: scope.row.id,
                                    status: scope.row.status,
                                    disabled:
                                        scope.row.status == 2 ? true : false
                                }
                            })}}},[_vm._v("编辑")]):_vm._e(),(scope.row.get_type == 2)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({
                                path: '/Team/grant',
                                query: { id: scope.row.id }
                            })}}},[_vm._v("卖家发放")]):_vm._e(),(scope.row.status == 1)?_c('ls-dialog',{staticClass:"inline m-l-12",attrs:{"title":"确认活动","content":("确认活动：" + (scope.row.name))},on:{"confirm":function($event){return _vm.TeamOpen(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("确认活动")])],1):_vm._e(),(scope.row.status == 2)?_c('ls-dialog',{staticClass:"inline m-l-12",attrs:{"title":"结束活动","content":("确定结束活动：" + (scope.row.name) + "？结束活动的活动不能重新开始，请谨慎操作")},on:{"confirm":function($event){return _vm.TeamStop(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("结束活动")])],1):_vm._e(),_c('ls-dialog',{staticClass:"inline m-l-12",attrs:{"title":"删除活动","content":("确定删除：" + (scope.row.name) + "（" + (scope.row.sn) + "）？请谨慎操作")},on:{"confirm":function($event){return _vm.TeamDel(scope.row.id)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("删除")])],1)]}}])})],1)],1),_c('div',{staticClass:"pane-footer m-t-16 flex row-right"},[_c('ls-pagination',{on:{"change":function($event){return _vm.$emit('refresh')}},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }