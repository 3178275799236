
































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import PopoverInput from '@/components/popover-input.vue'
import {
    apiTeamDel, //删除活动
    // apiTeamSort,  //活动排序
    apiTeamConfirm, //活动开始发放
    apiTeamStop //活动结束发放
} from '@/api/marketing/combination'

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput
    }
})
export default class TeamPane extends Vue {
    $refs!: { paneTable: any }
    @Prop() value: any
    @Prop() pager!: any

    // 删除活动
    TeamDel(ids: number) {
        apiTeamDel({
            id: ids
        }).then(() => {
            this.$message.success('修改成功!')
            this.$emit('refresh')
        })
    }

    // 开始活动
    TeamOpen(ids: number) {
        apiTeamConfirm({
            id: ids
        }).then(() => {
            this.$message.success('开启成功!')
            this.$emit('refresh')
        })
    }

    // 结束活动
    TeamStop(ids: number) {
        apiTeamStop({
            id: ids
        }).then(() => {
            this.$message.success('关闭成功!')
            this.$emit('refresh')
        })
    }

    // 活动排序
    // TeamSort(sort: string, id: number) {
    //     apiTeamSort({
    //         id,
    //         sort,
    //     }).then(() => {
    //         this.$emit("refresh");
    //     });
    // }
}
