



















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import teamPane from '@/components/marketing/team/team-pane.vue'
import { RequestPaging } from '@/utils/util'
import { apiTeamLists } from '@/api/marketing/combination'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'
import { teamType } from '@/utils/type'

@Component({
    components: {
        teamPane,
        DatePicker,
        ExportData
    }
})
export default class team extends Vue {
    apiTeamLists = apiTeamLists

    tabs = [
        {
            label: '全部',
            name: teamType[0]
        },
        {
            label: '未开始',
            name: teamType[1]
        },
        {
            label: '进行中',
            name: teamType[2]
        },
        {
            label: '已结束',
            name: teamType[3]
        }
    ]

    queryObj = {
        activity: '',
        status: '',
        goods: '',
        end_time: '',
        start_time: ''
    }
    lists = []
    tabCount = {
        all: 0, //全部
        not: 0, //未开始
        conduct: 0, //进行中
        end: 0 //已结束
    }
    pager = new RequestPaging()
    activeName: any = 'all'

    getList(page?: number): void {
        page && (this.pager.page = page)
        this.handleTabsChange()

        this.pager
            .request({
                callback: apiTeamLists,
                params: this.queryObj
            })
            .then(res => {
                this.tabCount = res?.extend
            })
    }

    handleTabsChange() {
        const active = this.activeName
        const TeamType = teamType as any
        const status = TeamType[active] === TeamType.all ? '' : TeamType[active]
        this.$set(this.queryObj, 'status', status)
    }

    resetQueryObj() {
        Object.keys(this.queryObj).map(key => {
            this.$set(this.queryObj, key, '')
        })
        this.getList()
    }
    created() {
        this.getList()
    }
}
